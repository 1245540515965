import { paypalStatus, paypalCallback, orderGetOrderDeail } from "@/api/pc"

export default {
	name: "shoppComplete",
	components: {

	},
	data: () => {
		return {
			news: {},
			paymentId: "",
			PayerID: "",
			countrySoyego: localStorage.getItem("countrySoyego"),
			strCurXSoyego: localStorage.getItem("strCurXSoyego"),
			shoppShow: true,
		}
	},
	watch: {
		'$route': 'fetchData'
	},
	created() {
		if (this.$route.query.success == "false") {
			// this.$message.error("Payment failure");
			this.$router.push({ path: '/' });
			return;
		} else if(this.$route.query.success == "true"){
			this.paymentId = this.$route.query.paymentId;
			this.PayerID = this.$route.query.PayerID;
			if(this.PayerID){
				this.getPayapl();
			}else{
				this.getOrderDeail();
			}
			// this.getOrderDeail();
		}
	},
	mounted() {
		this.fetchData();
	},
	computed: {

	},
	methods: {
		fetchData() {
			this.$forceUpdate();
		},
		getPayapl() {
			var that = this;
			paypalStatus({ paymentId: this.paymentId }).then(res => {
				if (res.code == 0 && res.data.pay_status == 0) {
					that.getPaypalCallback();
				} else {
					if (res.code == 0 && res.data) {
						that.getOrderDeail();
					}
				}
			}).catch(err => {
				// console.log(err)
			})
		},
		getPaypalCallback() {
			var that = this;
			paypalCallback({
				paymentId: this.paymentId,
				PayerID: this.PayerID
			}).then(res => {
				if (res.code == 0 && res.data == "success") {
					that.getOrderDeail();
				}
			}).catch(err => {
				this.$message.error(err.message)
				// console.log(err)
			})
		},
		getOrderDeail() {
			// let params = {
			// 	paymentId: this.paymentId
			// }
			orderGetOrderDeail({ paymentId: this.paymentId }).then(res => {
				if(res.code == 0 && res.data){
					this.news = res.data;
					this.shoppShow = false;
					let discount = (parseInt(this.news.platform_coupon_total_money) + this.news.promotion_price).toFixed(2);
					// + discount + "';"
					let dom = document.getElementById("uts_tm");
					if(dom){
						return;
					}else{
						let script = document.createElement('script');
						script.type = 'text/javascript';
						script.id = "soyegoId";
						script.innerHTML = "var uts_eventid = '5543';var uts_orderid = '"
						+ this.news.order_no + "';"
						+ "var uts_saleamount = '"
						+ this.news.goods_money + "';"
						+ "var uts_coupon = '';"
						+ "var uts_discount = '';"
						+ "var uts_currency = 'USD';"
						document.body.appendChild(script);

						let script1 = document.createElement('script');
						script1.type = 'text/javascript';
						script1.id = 'uts_tm';
						script1.src = '//www.linkconnector.com/uts_tm.php?cgid=901918';
						document.body.appendChild(script1);
					}
				}
				// console.log(res)
			}).catch(err => {
				this.$message.error(err.message)
				// console.log(err)
			})
		},
	},
	beforeDestroy() {
		let dom = document.getElementById("uts_tm");
		let dom1 = document.getElementById("soyegoId");
		if(dom){
			dom.remove();
			dom1.remove();
		}
	}
}