<template>
    <div class="shoppComplete">
        <div class="shoppComplete-header">
            <div class="warp">
                <div class="logo">
                    <div class="img" @click="$router.push('/index')">
                        <img src="@/assets/images/goods/soyego.png" />
                    </div>
                    <div class="pay">
                        <div class="img"><img src="@/assets/images/goods/Lock.png" /></div>
                        <span>SECURE PAYMENT</span>
                    </div>
                </div>
                <div class="text">
                    <span>Continue Shopping</span>
                    <img src="@/assets/images/goods/r.png" />
                </div>
            </div>
        </div>
        <div class="shoppComplete-order">
            <div class="warp">
                <div class="order-top">
                    <div class="text complete">
                        <div class="img"><img src="@/assets/images/goods/bag.png" /></div>
                        <span>BAG</span>
                    </div>
                    <div class="line complete-line">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="text complete">
                        <div class="img"><img src="@/assets/images/goods/order1.png" /></div>
                        <span>Submit Order</span>
                    </div>
                    <div class="line complete-line">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="text complete">
                        <div class="img"><img src="@/assets/images/goods/payment1.png" /></div>
                        <span>Payment</span>
                    </div>
                    <div class="line complete-line">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="text ative">
                        <div class="img"><img src="@/assets/images/goods/complete1.png" /></div>
                        <span>Complete</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="order-main" v-loading="shoppShow">
            <div class="success">
                <div class="img"><img src="@/assets/images/goods/success.png" /></div>
                <span>Payment Successful !</span>
            </div>
            <div class="done">
                <span>Your order was successfully processed</span>
            </div>
            <div class="order">
                <span>Order NO. : {{news.order_no}}</span>
            </div>
            <div class="details">
                <h5>Payment Details</h5>
                <div class="warp">
                    <div class="main">
                        <div class="left">
                            <span>Retail Price: {{countrySoyego}}{{strCurXSoyego}}{{news.goods_money}}</span>
                            <span>Logistics: {{countrySoyego}}{{strCurXSoyego}}{{news.delivery_money}}</span>
                        </div>
                        <div class="right">
                            <span>Discount: -{{countrySoyego}}{{strCurXSoyego}}{{( parseInt(news.platform_coupon_total_money) + news.promotion_price).toFixed(2)}}</span>
                            <span>Total: <b>{{countrySoyego}}{{strCurXSoyego}}{{news.order_money}}</b></span>
                        </div>
                    </div>
                </div>
            </div>
            <button @click="$router.push({ path: '/index' })">OK</button>
            <img :src="'https://www.shareasale.com/sale.cfm?tracking=' + news.order_no + '&amount=' + news.goods_money + '&merchantID=122901&transtype=sale'" width="1" height="1" />
        </div>
       
    </div>
</template>
<script src="https://www.dwin1.com/19038.js" type="text/javascript" defer="defer"></script>

<!-- Start UTS Confirm Code -->
<!-- Generic Cart Cart Variables -->
<!-- <script type="text/javascript">
    var uts_eventid = '5543';
    var uts_orderid = news.order_no;
    var uts_saleamount = news.goods_money;
    var uts_coupon = '';
    var uts_discount = '';
    var uts_currency = 'USD';
</script> -->
<!-- End UTS Confirm Code -->
<!-- <script type="text/javascript" id="uts_tm" src="https://www.linkconnector.com/uts_tm.php?cgid=901918"></script> -->

<style lang="scss" scoped>
@import "./css/shoppComplete.scss";
</style>

<script>
import shoppComplete from "./js/shoppComplete.js"

export default {
    name: "shoppComplete",
    components: {},
    mixins: [shoppComplete]
}
</script>
